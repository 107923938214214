import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  Micrfrontend: "8d0e5773c34f",
  Frameworks: "8d0e5773c34f",
  Plugins: "8d0e5773c34f",

  Birlestirme: "dec3847a0634",
  Iframe: "dec3847a0634",
  ShadowDom: "dec3847a0634",
  WebComponents: "dec3847a0634",

  Platforms: "5e3343b820d4",
  ModuleFederation: "abb405888e85",
}

const stories = [
  {
    title: "Microfrontend Frameworks ve Pluginleri",
    postId: "8d0e5773c34f",
  },
  {
    title: "Ölçeklenebilir Ürün Geliştirme Altyapısı",
    postId: "5e3343b820d4",
  },
  {
    title: "Microfrontend Birleştirme Yöntemleri",
    postId: "dec3847a0634",
  },
  {
    title: "Microfrontend Birleştirme Yöntemleri - Module Federation",
    postId: "abb405888e85",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Microfrontends",
  path: "react-microfrontends",
}

const ReactMFEPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactMFEPage
